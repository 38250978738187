body {
    -webkit-user-select: none; /* Chrome, Safari, Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-tap-highlight-color: transparent; /* iOS Safari/Chrome Android */
}


.item-container {
    width: 33.33%;
    float: left;
    cursor:pointer;
    padding: 10px;
}
.item-container-content {
    margin: 10px;
    border: 1px solid #f0f0f0;
    background: white;
    text-align: center;
    border-radius: 10px;
    width:100%;
}
.item-info-block .price {
    padding: 20px;
}
.item-container-content:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0 10px 30px;
    transform: translateY(-3px);
}
.item-product-img {
    width: auto;
    height: 200px;
    max-height: 200px;
    padding: 20px;
    max-width: 100%;
}

.item-info-block {
    width: 100%;
    color: rgba(0, 0, 0, 0.7);
    padding: 0 10px;
    font-weight: 500;
    font-size: 17px;
}
.title {
    width: 100%;
    margin-top: 10px;
    height: 80px;
}
.img-fluid.brand-logos {
    max-width: 100%;
    height: auto;
    cursor: pointer;
}
.price {
    width: 100%;
    text-align: center;
    margin-top: 5px;
    font-weight: 700;
}
#products-container {
    height: 70vh;
    overflow-y: auto;
}
.price span {
    font-size: 20px;
}

.add-tocart-button {
    height: 50px;
    border-radius: 5px;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #cee532;
    margin: 25px 0;
    padding: 10px;
    width: 250px;
    font-size: 20px;
}

.error-popup {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 124px;
    width: 420px;
    background: #e8e8e8;
    border: 1px solid #797979;
    padding: 30px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    z-index: 111111;
    border-radius: 10px;
}
.error-popup button{

}

.kiosk-header {
    width: 100%;
    position: relative;
    z-index: 20;
}

.kiosk-nav {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px;
}

.cart-items-block {
    display: flex;
    overflow: auto;
    flex-wrap: nowrap;
    gap: 0px;
    height: 170px;
    width: 100%;
}
.cart-item {
    width: 400px;
    float: left;
    padding: 10px;
    flex-shrink: 0;
}
cart-items-block::-webkit-scrollbar {
    width: 0 !important;
    display: none !important
}
cart-items-block {
    scrollbar-width: none;
}
.cart-item h3 {
    font-size: 15px;
    font-weight: 600;
}
.cart-container {
    float: left;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: white;
    min-height: 340px;
}
.cart-container-header {
    background: #cee532;
    position: relative;
    float: left;
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 10px;
}
.cart-container-total{
    width: 30%;
    float: right;
    font-size: 30px;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px 0px #868686;
    text-align: right;
    padding-right: 10px;
}
.cart-container-header .cart-icon
{
    font-size: 30px;
}
.cart-container-cart-badge {
    float: right;
    margin-right: 12px;
}
.cart-title {
    font-size: 30px;
    font-weight: 700;
    color: white;
    width: 70%;
    float: left;
    text-align: left;
    text-shadow: 1px 1px 0px #868686;
}
.cart-item-delete-button {
    color: #ff3030;
    border-radius: 10px;
    padding: 5px 0px;
    font-size: 30px;
    cursor: pointer;
    margin: auto;
    height: 50%;
}
.cart-item-modal-inner-right .cart-item-delete-button {
    color: #ff3030;
    font-size: 50px;
    cursor: pointer;
}
.cart-item-delete-button:hover {
    background: #ff3030;
    color: white;
}

.cart-item-inner {
    background: #eaeaea;
    float: left;
    padding: 10px;
    width: 100%;
    height: 150px;
    overflow-y: scroll;
    border-radius: 10px;
}
.cart-item-mini-inner-bottom {
    position: relative;
    float: left;
    width: 100%;
}
.cart-item-inner .cart-item-quantity {
    font-size: 35px;
    font-weight: 700;
    color: #cee532;
    text-shadow: 1px 1px 1px #464646;
    text-align: center;
}
.cart-item-inner-left{
    float:left;
    width:80%;
}
.cart-item-inner-right{
    float:right;
    width:20%;
}


.logo {
    color: #fff;
    font-size: 20px;
}
.search_header {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.kiosk-header .language-selector {
    position: absolute;
    top: 25px;
    right: 50px;
}
.cart-container .badge {
    color: red;
    font-weight: 700;
    font-size: 30px;
    vertical-align: middle;
}
.cart-block {
    float: left;
    width:100%;
}
.cart-block-no-items {
    float: left;
    width:100%;
    background: #cccccc;
    position: fixed;
    bottom: 0;
    left: 0;
}
.cart-block-no-items-cart-title {
    font-size: 20px;
    font-weight: 700;
    color: white;
    width: 70%;
    float: left;
    text-align: left;
    padding: 20px;
}
.cart-block-button-inner {
    float: left;
    width: 50%;
    padding: 30px 10px;
}
.cart-clear-button{
    float: left;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    font-size: 30px;
    font-weight: 700;
    color: #7b7b7b;
    background: rgb(232, 232, 232);
    text-align: center;
    border-radius: 10px;
}
.cart-checkout-button {
    background: #cee532;
    float: left;
    padding: 10px;
    width: 100%;
    cursor:pointer;
    font-size: 30px;
    font-weight: 700;
    color: white;
    text-align: center;
    border-radius: 10px;
    text-shadow: 1px 1px 0px #868686;
}
.cart-order-button {
    background: #cee532;
    float: left;
    padding: 10px;
    width: 100%;
    cursor:pointer;
    font-size: 30px;
    font-weight: 700;
    color: white;
    text-align: center;
    border-radius: 10px;
    text-shadow: 1px 1px 0px #868686;
}
.transaction-content , .transaction-message, .transaction-actions {
    margin: 50px 0px;
    float: left;
    width: 100%;
    padding: 0px 150px;
}
.cart-block-button-checkout-inner{
    width: 100%;
    padding: 10px;
    float: left;
}
.cart-return-button{
    float: left;
    padding: 10px;
    width: 100%;
    cursor:pointer;
    font-size: 30px;
    font-weight: 700;
    color: white;
    background: rgb(128, 128, 128);
    text-align: center;
}
.loading-spinner {
    margin: 50px 0px;
}
.navigation-container {
    /*background: #cee532;*/
    margin: 20px 10px 0;
    padding: 10px;
    color: white;
    font-size: 20px;
    font-weight: 700;
    /*border-bottom: 3px rgb(128, 128, 128) solid;*/
}
.navigation-container button {
    color: white;
    font-size: 20px;
    font-weight: 700;
}
.navigation-go-back-button {
    padding-top: 5px;
}
.loader-block{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100000;
    background: gray;
    opacity: 0.5;
}


.loader-block-text{
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    color: red;
}


.item-details-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.item-details-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    width: 100%;
    bottom: 0;
    position: sticky;
    max-height: 80vh;
    top: 0;
    margin: auto;
    height: auto;
}
.item-details-top-content-left-side{
    float:left;
    width:30%;
}
.item-details-top-content-left-side img {
    max-height: 200px;
}
.item-details-top-content-right-side{
    float:left;
    width:70%;
}
.item-details-block {
    margin: 20px 0;
    padding: 20px;
}
.item-details-block-data {
    font-size: 20px;
}
.item-details-block-title{
    font-size: 25px;
    font-weight: 700;
    margin: 0px 0px 15px;
    width: 100%;
    border-bottom: 1px solid gray;
    padding: 0px 0px 5px;
}
.item-details-top-content {
    width: 100%;
    margin: 20px 0;
    float: left;
    padding: 20px;
}
.item-details-middle-content {
    width: 100%;
    margin: 20px 0;
    float: left;
}
.item-details-bottom-content {
    width: 100%;
    margin: 20px 0;
    float: left;
}
.item-details-close-button, .item-details-add-to-cart-button {
    margin: auto;
    width: 50%;
    text-align: center;
    font-size: 25px;
}

.item-details-add-to-cart-button button, .item-details-close-button button {
    padding: 10px 20px;
    font-weight: 700;
    color: #777;
    background: rgb(227, 227, 227);
    width: 90%;
    border: none !important;
    border-radius: 10px !important;
}
.item-details-add-to-cart-button button {
    background: #cee532;
    color: white;
    text-shadow: 1px 1px 0px #868686;
}

.item-details-name {
    padding: 20px;
    font-weight: 700;
    font-size: 30px;
    float: left;
    width: 70%;
}
.item-details-price {
    padding: 20px;
    font-weight: 700;
    font-size: 50px;
    float: right;
    width: 30%;
    color: #cee532;
    text-align: center;
    text-shadow: 1px 1px 1px #464646;
}

.cart-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    border-bottom: 1px solid rgb(128, 128, 128);
}
.cart-modal-backdrop {
    background: white;
    position: relative;
    float: left;
    height: 100%;
    width: 100%;
}
.cart-modal-title {
    position: relative;
    width: 100%;
    height: 13vh;
    max-height: 13vh;
}
.cart-modal-title-inner {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 40px;
    font-weight: 700;
}
.cart-item-modal {
    position: relative;
    float: left;
    width: 100%;
}
.cart-item-modal-inner {
    background: #f7f7f7;
    float: left;
    width: 100%;
    overflow-y: scroll;
    padding: 20px 20px;
    margin: 25px 0px 0px;
}
.cart-item-modal-image img {
    height: 180px;
    padding: 10px;
    border-radius: 10px;
    background: white;
}
.cart-item-mini-image {
    float: left;
    background: white;
    max-height: 100%;
    max-width: 100%;
}
.cart-item-mini-image img {
    padding: 5px;
    border-radius: 10px;
    background: white;
    max-height: 125px;
    max-width: 100%;
    height: auto;
}
.cart-item-mini-inner-left{
    float:left;
    width:30%;
    height: 130px;
    max-height: 130px;
}
.cart-item-mini-inner-middle{
    float:left;
    width:55%;
    height: 130px;
    max-height: 130px;
}
.cart-item-mini-inner-right{
    float:right;
    width:15%;
    height: 130px;
    max-height: 130px;
}
.cart-item-modal-inner-left{
    float:left;
    width:30%;
    height: 200px;
}
.cart-item-modal-inner-middle{
    float:left;
    width:55%;
    height: 200px;
}
.cart-item-modal-inner-right{
    float:right;
    width:15%;
    height: 200px;
}
.cart-item-modal-inner-bottom {
    position: relative;
    float: left;
    width: 100%;
}
.cart-item-modal-inner-details-left{
    width: 30%;
    float: left;
}
.cart-item-modal-inner-details-right{
    width: 70%;
    float: left;
    padding: 20px;
}

.cart-item-image img {
    height: 180px;
    border: 1px solid black;
}
.cart-item-name {
    float: left;
    padding: 15px;
    width: 100%;
}
.cart-item-barcode {
    float: left;
    padding: 15px;
    width: 100%;
}
.cart-item-price {
    float: left;
    padding: 15px;
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    height: 50%;
}
.cart-item-image img {
    height: 150px;
}
.cart-modal-items {
    position: relative;
    float: left;
    overflow-y: scroll;
    height: 72vh;
    max-height: 72vh;
    width: 100%;
}
.cart-modal-total-text{
    float: left;
    padding-left: 20px;
    font-size: 40px;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px 0px #868686;
}
.cart-modal-total-price{
    padding-right: 20px;
    font-size: 40px;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px 0px #868686;
    text-align: right;
}
.cart-item-quantity-selector-title {
    float: left;
    line-height: 50px;
    width: 100%;
    padding: 10px 0px;
    font-weight: 700;
}
.cart-modal-total {
    position: relative;
    float: left;
    height: 5vh;
    max-height: 6vh;
    width: 100%;
    font-size: 37px;
    background: #cee532;
    font-weight: 700;
    color: white;
    padding: 20px;
    line-height: 40px;
}

.cart-item-modal .cart-item-price {
    padding: 20px;
    font-weight: 700;
    font-size: 50px;
    float: left;
    width: 100%;
    color: #cee532;
    text-align: center;
    text-shadow: 1px 1px 1px #464646;
}

.item-details-quantity-selector, .cart-item-modal-quantity-selector {
    margin: 50px;
    text-align: center;
    font-size: 25px;
    position: relative;
    height: 120px;
}
.cart-item-modal-quantity-selector {
    margin: 0px 0px 50px;
}
.item-details-quantity-selector-container {
    border: 1px solid #d7d7d7;
    width: 302px;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
}
.cart-item-quantity-selector-input[data-quantity="0"] {
    color: #777;
    text-shadow: 0px 0px 0px;
}
.item-details-quantity-selector .qty-select-button, .cart-item-modal-quantity-selector .qty-select-button {
    width: 80px;
    height: 60px;
    background-color: rgb(128, 128, 128);
    font-size: 50px;
    line-height: 50px;
    vertical-align: middle;
    border-radius: 5px !important;
    border: none;
    color: white;
    font-weight: 700;
}
.quantity-more-button.qty-select-button {
    background-color: #cee532;
    text-shadow: 1px 1px 0px #868686;
}


.cart-item-quantity-selector-input {
    width: 140px;
    height: 60px;
    vertical-align: middle;
    text-align: center;
    font-weight: 600;
    border: none !important;
    text-shadow: 1px 1px 1px #464646;
    color: #cee532;
    font-size: 50px;
}

.age-restriction-popup {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    background: rgba(128, 128, 128, 0.8);
    left: 0;
    z-index: 1111;
}
.age-restriction-popup-block {
    position: absolute;
    top: 0;
    background: white;
    left: 0;
    right: 0;
    text-align: center;
    padding: 50px;
    margin: 100px;
    border-radius: 20px;
}

.age-restriction-popup-message-text {
    font-size: 30px;
    color: red;
    font-weight: 500;
    margin: 25px 0px;
}
.age-restriction-popup-message-text-content {
    font-size: 25px;
    margin: 25px 0px;
}
.age-restriction-popup-message-button {
    background: #10c168;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 20px;
    border-radius: 10px !important;
    border: none !important;
    color: white;
    margin: 20px 20px;
    width: 250px;
}
.age-restriction-popup-message-button.restriction-button-noconfirm {
    background: red;
}


.max-quantity-message-popup {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    background: rgba(128, 128, 128, 0.46);
    left: 0;
    z-index: 1111;
}
.max-quantity-message-block {
    position: absolute;
    bottom: 280px;
    background: white;
    left: 0;
    right: 0;
    text-align: center;
    padding: 50px;
    margin: 100px;
    border-radius: 20px;
}
.max-quantity-message-icon {
    margin: 25px 0px;
}
.max-quantity-message-text {
    font-size: 20px;
    color: red;
    font-weight: 700;
    margin: 25px 0px;
}
.max-quantity-message-text-content {
    font-size: 20px;
    margin: 25px 0px;
}

.max-quantity-message-button {
    background: #10c168;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 20px;
    border-radius: 10px !important;
    border: none !important;
    color: white;
    margin: 20px 0px;
    width: 250px;
}
.cart-modal-actions {
    position: relative;
    float: left;
    width: 100%;
    background: white;
    padding: 20px;
    height: 10vh;
    max-height: 10vh;
}
.checkout-block {
    z-index: 10000;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    background: #fff;
    width: 100%;
    height: 100vh;
    box-shadow: 0px 10px 100px black;
    display: flex; /* Enable Flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}
.loading-payment-title {
    font-size: 35px;
    font-weight: 600;
    margin: 100px 0px;
    float: left;
    width: 100%;
}
.transaction-message {
    font-size: 30px;
    font-weight: 300;
}
.cart-payment-button {
    float: left;
    width: 50%;
    cursor: pointer;
}
.cart-payment-button-title {
    padding: 10px;
    font-size: 30px;
    font-weight: 300;
}

.cart-receipt-image {
    margin-top: 100px;
    float: left;
    width: 100%;
    padding: 10px;
    font-size: 30px;
    font-weight: 300;
}
.cart-receipt-text{
    margin-top: 20px;
    font-weight: 600;
}

.order-thankyou-text{
    font-size: 30px;
    font-weight: 400;
}
.credit-info{
    font-size: 30px;
}
.credit-info-need{
    font-weight:700;
}
.credit-info-given{
    font-weight:500;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #ddd;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ddd transparent transparent transparent;
}

.start-shopping-container {
    height: 100vh;
    width: 100vw;
    /*background-repeat: repeat-y;*/
    /*background-image: url("/img/shop_background_tile.png");*/
    /*background-size: 100% auto;*/
}

.loading-popup .payment-icon, .loading-popup .dispense-icon, .loading-popup .transaction-icon{
    padding: 50px 0px;
}
.shop-background {
    width: 100%;
    height: 100%;
}
.start-shopping-container-block {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    text-align: center;
    height: 300px;
}
.start-shopping-container-block-shopw-now {
    width: 100%;
    clear: both;
    text-align: center;
}
.start-shopping-button {
    height: 100px;
    background: #c1d400;
    color: white;
    font-weight: 700;
    font-size: 50px;
    text-align: center;
    padding: 5px 10px;
    line-height: 80px;
    border: 2px solid black;
    box-shadow: 10px 10px 10px black;
    cursor: pointer;
    border-radius: 5px;
    margin: auto;
    width: 60%;
    clear: both;
}
.start-shopping-button-icon img {
    max-width: 200px;
    border-radius: 150px;
    background: black;
}
.start-shopping-button-icon.english-language img {
    border: 5px solid;
}
.start-shopping-button-icon img:hover {
    background: #c1d400;
    border-color: #c1d400;
}
.start-shopping-button-icon.english-language {
    text-align: right;
}
.start-shopping-button-icon.greek-language {
    text-align: left;
}
.start-shopping-button-icon {
    float: left;
    width: 50%;
    margin-top: 100px;
    cursor: pointer;
    background: #000000a3;
    padding: 50px;
}
.language-icons-container .language-icon {
    display: inline-block;
    margin: 0px 20px;
    border: 3px solid black;
    cursor: pointer;
}
.language-icons-container {
    height: 150px;
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 100px;
}
.start-shopping-button:hover {
    background: black;
    border: 0;
}
.category-card {
    width: 33.33%;
    padding: 20px;
    float: left;
    background: #86ae78;
    border: 10px solid #f2f6fc;
    height: 350px;
    font-size: 30px;
    font-weight: 700;
    color: white;
    position: relative;
    border-radius: 20px;
    cursor:pointer;
}
.category-icon {
    position: absolute;
    opacity: 0.5;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 50px;
}
.category-block-text {
    position: absolute;
    bottom: 0;
    padding: 20px 20px;
    left: 0;
    font-size: 25px;
    text-align: center;
    width: 100%;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@media screen and (min-width: 500px) {

}

@media screen and (min-width: 660px) {


}

@media screen and (min-width: 800px) {


}

@media screen and (min-width: 1000px) {


}
